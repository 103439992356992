<template>
    <div class="header-root">
        <div
            class="header-wrapper"
            :class="{ 'mobile-header-wrapper': mobile }"
        >
            <div class="header-left">
                <div class="header-input-wrapper">
                    <div class="header-input-inner-wrapper">
                        <div
                            id="header-input-inner-wrapper"
                            class="headerInputInnerWrapper"
                            @removeAutoFill="removeAutoFill"
                            @addAutoFill="addAutoFill"
                        >
                            <auto-complete-input
                                v-model="query"
                                :initValue="query"
                                ref="queryInput"
                                id="header-input"
                                class="headerInput"
                                :group="-1"
                                placeholder="Search web or for manuals"
                                @submitQuery="onInputSubmit"
                                @selected="onInputSubmit"
                                @return="onInputSubmit"
                                @iconClick="onInputSubmit"
                            ></auto-complete-input>
                        </div>
                    </div>
                </div>
                <div
                    class="header-button-wrapper"
                    :class="{ 'mobile-header-button-wrapper': mobile }"
                >
                    <div
                        class="header-button"
                        ref="submitBtn"
                        @click="onManualsSearchSubmit"
                    >
                        <span v-if="mobile"
                            ><i class="mobile-button-icon pi pi-search"></i
                        ></span>
                        <span v-else>Manuals Search</span>
                    </div>
                </div>
            </div>
            <div class="header-right">
                <img
                    v-if="mobile"
                    class="mobile-header-logo-image"
                    src="/img/icon.png"
                    @click="onLogoClick"
                />
                <svg v-else class="header-logo-image-wrapper">
                    <image
                        class="header-logo-image"
                        xlink:href="/img/logo.svg"
                        @click="onLogoClick"
                    />
                </svg>
            </div>
        </div>
        <component
            :is="yahooHeaderComponent"
            v-if="$features('yahoo-links')"
            :query="query"
            :enteredQuery="enteredQuery"
            :typeTag="typeTag"
        ></component>
    </div>
</template>

<script>
import queryString from "query-string";
import AutoComplete from "primevue/autocomplete";
import fetchJsonp from "fetch-jsonp";
import AutoCompleteInput from "../autoCompleteInput/AutoCompleteInput.vue";
import { trackGA } from "@/analytics";
import { defineAsyncComponent } from "vue";

export default {
    name: "searchables-header",
    props: ["currentQuery", "mobile"],
    components: {
        AutoComplete,
        AutoCompleteInput,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            query: this.currentQuery || "",
            autoCompleteSuggestions: null,
            typeTag: qs.type || "",
            enteredQuery: qs.q || null,
            trackGA,
        };
    },
    computed:{
        yahooHeaderComponent() {
            return defineAsyncComponent(() =>
                import(`./YahooHeader.vue`)
            );
        }
    },
    watch: {
        currentQuery(nv) {
            this.query = nv;
        },
    },
    methods: {
        onInputSubmit(query) {
            this.$emit("inputSubmit", { query });
        },
        onManualsSearchSubmit(query) {
            this.$emit("inputSubmit", {
                query: this.$refs.queryInput.query,
                isManaulsSearch: true,
            });
        },
        onLogoClick() {
            this.$emit("logoClick");
        },
        async getAutocompleteSuggestions(ev) {
            const term = ev.query;
            const res = await this.manualsAutocompleteSearch(term);
            this.autoCompleteSuggestions = res;
        },
        async manualsAutocompleteSearch(term) {
            const body = {
                group: 12,
                q: term,
                field: "title",
            };

            try {
                const res = await fetchJsonp(
                    `https://us.search.yahoo.com/sugg/gossip/gossip-us-partner?output=jsonp&appid=tig&command=${term}`
                );

                if (!res.ok) {
                    return await res.json().catch(e => ({
                        error: "Bad Response",
                        query: term,
                        results: [],
                    }));
                }
                const data = await res.json();

                // Mimic our indexer results
                const records = data.gossip.results.map(el => ({
                    text: el.key,
                }));
                return records;
            } catch (e) {
                console.error(`Error with autocomplete call \n ${e}`);
            }

            return null;
        },
    },
};
</script>

<style>
.header-wrapper {
    display: grid;
    grid-template-columns: 50vw auto;
    height: 78px;
    width: 78vw;
    margin: 25px auto 0;
}

.header-left {
    grid-column-start: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 5px;
}

.header-right {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
}

.header-logo-image {
    width: 150px;
}

.header-logo-image-wrapper {
    height: 60px;
    padding-top: 10px;
    width: 150px;
}

.header-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}

.header-input-inner-wrapper {
    width: 100%;
    border: 1px solid #999999;
    border-radius: 20px;
}

.header-input {
    padding: 0px 15px;
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 20px;
    font-size: 1.075em;

    outline: none !important;
}

.header-input .p-autocomplete-input {
    width: 100%;
    border: none;
    outline: none !important;
    outline-width: 0 !important;
}

.header-input.p-inputwrapper-focus {
    outline: none !important;
    outline-width: 0 !important;
}

.header-input .p-autocomplete-input:focus {
    outline: none !important;
    outline-width: 0 !important;
}

.header-input-focus {
    outline-width: 0 !important;
    outline: none !important;
}

.header-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
}

.header-button {
    border-radius: 20px;
    background-color: #0857e8;
    color: white;
    padding: 10px 8px;
    margin: 0px 0px 14px 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.headerInputInnerWrapper {
    position: relative;
    z-index: 20;
}

/** Mobile Styling */

.header-wrapper.mobile-header-wrapper {
    grid-template-columns: 1fr auto;
    width: 94vw;
    margin: 25px auto 0;
}

.mobile-header-logo-image {
    width: 50px;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
}

.mobile-header-button-wrapper {
    width: 75px;
}

.mobile-button-icon {
    font-weight: bold;
}
/** End Mobile Styling */
</style>
